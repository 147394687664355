import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import ExamService from "../../service/exam.service";
import PageNavigation from "../components/PageNavigation";
import ActivityPartPreview from "./ActivityPartPreview";
import InputLink from "../components/InputLink";


const newActivityPart = {

    id: -1,
    deletedAt: null,
    status: "ACTIVE",
    type: "TEXT",
    content: "",
    title: "",
    url: "",
    file: null,
    answer: "",
    orderNumber: 1,
    rubric: "",
    isMain: false,
    duration: 0,
    repeatCount: 1,
    score: 1.0,
    gains: []

}

export default function ActivityPartEdit() {

    let {examId, activityId, activityPartId} = useParams();
    const [selectedActivityPart, setSelectedActivityPart] = useState(newActivityPart);
    const fileInputRef = useRef(null);
    const [, setIsNew] = useState(false);
    const [uploadFile, setUploadFile] = useState(null);
    const [, setFileUploadStatus] = useState(null);
    const navigate = useNavigate();
    const [gainList, setGainList] = useState([]);

    const [selectedGain, setSelectedGain] = useState(null);



    useEffect(() => {
        if (activityPartId === "new") {
            setIsNew(true);
            setSelectedActivityPart(newActivityPart);
            return;
        }
        ExamService.getActivityPartById(activityPartId).then((response) => {
            setSelectedActivityPart(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        ExamService.getGains().then((response) => {
            setGainList(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const saveActivityPartEvent = () => {

        if (activityPartId === "new") {
            ExamService.createActivityPart(activityId, selectedActivityPart).then((response) => {
                setSelectedActivityPart(response);
                navigate('/exam/' + examId);
            });
        } else {
            ExamService.updateActivityPart(activityPartId, selectedActivityPart).then((response) => {
                setSelectedActivityPart(response);
                navigate('/exam/' + examId);
            });
        }
    }


    const deleteActivityPartEvent = () => {

        if (activityPartId !== "new") {

            ExamService.deleteActivityPart(activityId, activityPartId).then((response) => {

                navigate('/exam/' + examId);
            });
        }
    }




    const deleteGainEvent = (deletedGain) => {
        const param = {
            id: deletedGain,
            activityPartId: selectedActivityPart.id
        }
        ExamService.removeGain(param).then((response) => {
            setSelectedActivityPart(response);
        });
    }
    const addGainEvent = (e) => {
        e.preventDefault();

        console.log("BURADA")

        if(selectedGain){
            const _gain = gainList.find(gain => Number(gain.KazanimID) === Number(selectedGain));
            const param = {
                code: Number(_gain.KazanimID),
                gain: _gain.KazanimAd,
                subject: _gain.KonuAd,
                activityPartId: selectedActivityPart.id
            }
            ExamService.addGain(param).then((response) => {
                setSelectedActivityPart(response);
            });
        }



    }




    const handleOnChange = (value) => {
        setUploadFile(value[0]);
    };

    useEffect(() => {
        if (uploadFile) {
            handleSendSingleFileUpload(uploadFile);
        }
    }, [uploadFile]);


    const handleSendSingleFileUpload = (file) => {
        setFileUploadStatus("UPLOADING");
        const formData = new FormData();
        formData.append("file", file);
        ExamService.uploadFileToPath(formData).then((response) => {
            setFileUploadStatus("UPLOADED");
            selectedActivityPart.url = response.data.id;
            setSelectedActivityPart(JSON.parse(JSON.stringify(selectedActivityPart)));
        });

    };


    return (


        selectedActivityPart && selectedActivityPart.id ?
            <div>
                <PageNavigation main="Componen List" sub="Home" header="Question Operations" description="Activity Operations"/>
                <div className="row my-3">
                    <div className="col-12 col-xl-8">
                        <div className="card card-body border-0 shadow mb-4">
                            <h2 className="h5 mb-4">Question Part</h2>
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Question Digital Type</label>
                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                type: e.target.value
                                            })}
                                            value={selectedActivityPart.type}
                                        >
                                            <option value="TEXT">TEXT</option>
                                            <option value="IMAGE">IMAGE</option>
                                            <option value="AUDIO">AUDIO</option>
                                            <option value="VIDEO">VIDEO</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3 form-check form-switch">
                                    <div>

                                        <label htmlFor="first_name">Is Main?</label>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={(e) =>
                                                setSelectedActivityPart({
                                                    ...selectedActivityPart,
                                                    isMain: !selectedActivityPart.isMain
                                                })
                                            }
                                            checked={selectedActivityPart.isMain}
                                        />

                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label htmlFor="zip">Order Number</label>
                                        <input
                                            className="form-control"
                                            id="zip"
                                            type="number"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                orderNumber: e.target.value
                                            })}
                                            value={selectedActivityPart.orderNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 mb-3">
                                    <div>
                                        <label htmlFor="first_name">Status</label>

                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                status: e.target.value
                                            })}
                                            value={selectedActivityPart.status}
                                        >
                                            <option value="ACTIVE">ACTIVE</option>
                                            <option value="PASSIVE">PASSIVE</option>
                                        </select>

                                    </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Title</label>
                                        <input
                                            type={"text"}
                                            className="form-control"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                title: e.target.value
                                            })}
                                            value={selectedActivityPart.title}
                                        />
                                    </div>
                                </div>


                                {selectedActivityPart.type === "TEXT" ? <div className="col-md-12 mb-3">
                                    <div>
                                        <label htmlFor="last_name">Content</label>
                                        <textarea
                                            className="form-control"
                                            rows="10"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                content: e.target.value
                                            })}
                                            value={selectedActivityPart.content}
                                        />
                                    </div>
                                </div> : null}


                                {selectedActivityPart.isMain ? <div className="col-md-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="email">Answer</label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            type="email"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                answer: e.target.value
                                            })}
                                            value={selectedActivityPart.answer}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.type !== "TEXT" ? <div className="col-sm-8 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="city">Document Url</label>
                                        <input
                                            className={`form-control`}
                                            style={{pointerEvents: selectedActivityPart.type !== "VIDEO" ? "none" : "auto"}}
                                            id="city"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                url: e.target.value
                                            })}
                                            value={selectedActivityPart.url}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.type !== "TEXT" && selectedActivityPart.type !== "VIDEO" ?
                                    <div className="col-sm-4 mb-3">
                                        <label htmlFor="state">File</label>


                                        <input
                                            className="form-control"
                                            ref={fileInputRef}
                                            type="file"
                                            multiple={false}
                                            accept=".mp3,.mp4,.wav,.png,.jpg,.jpeg"
                                            value={null}
                                            onChange={(event) => {
                                                handleOnChange({
                                                    ...event.target.files,
                                                });
                                            }}
                                            onClick={(event) => {
                                                event.target.value = null;
                                            }}
                                        />


                                    </div> : null}
                                {false && selectedActivityPart.isMain ? <div className="col-sm-12 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="address">Rubric</label>
                                        <input
                                            className="form-control"
                                            id="address"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                rubric: e.target.value
                                            })}
                                            value={selectedActivityPart.rubric}
                                        />
                                    </div>
                                </div> : null}
                                {false && selectedActivityPart.isMain ? <div className="col-sm-4 mb-3">
                                    <div className="form-group">
                                        <label htmlFor="city">Duration</label>
                                        <input
                                            className="form-control"
                                            id="city"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                duration: e.target.value
                                            })}
                                            value={selectedActivityPart.duration}
                                        />
                                    </div>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-6 mb-3">
                                    <label htmlFor="state">Repeat Count</label>
                                    <select
                                        className="form-select w-100 mb-0"
                                        onChange={(e) => setSelectedActivityPart({
                                            ...selectedActivityPart,
                                            repeatCount: e.target.value
                                        })}
                                        value={selectedActivityPart.repeatCount}
                                        aria-label="State select example"
                                    >
                                        <option value="1" selected="selected">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="1000">-</option>
                                    </select>
                                </div> : null}
                                {selectedActivityPart.isMain ? <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="zip">Max. Score</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => setSelectedActivityPart({
                                                ...selectedActivityPart,
                                                score: e.target.value
                                            })}
                                            value={selectedActivityPart.score}
                                        />
                                    </div>
                                </div> : null}
                                <div className="mt-3">
                                    <button onClick={saveActivityPartEvent}
                                            className="btn btn-gray-800 mt-2 animate-up-2">
                                        Question Save
                                    </button>


                                </div>



                                {
                                    activityPartId !== "new" ?
                                        <div className="text-end">
                                            <InputLink clickEvent={deleteActivityPartEvent} name="Question Delete"
                                                       type="delete"/></div>

                                        : null
                                }



                            </div>
                        </div>

                        {
                            selectedActivityPart.isMain ?
                                <div className="card card-body border-0 shadow mb-4 mb-xl-0">
                                    <h2 className="h5 mb-4">Gains</h2>
                                    <ul className="list-group list-group-flush">

                                        <select
                                            className="form-select w-100 mb-0"
                                            onChange={(e) => setSelectedGain(e.target.value)}
                                            value={selectedGain}
                                        >
                                            {
                                                gainList.map((gain, key) => (
                                                    <option key={key} value={gain.KazanimID}>{gain.KazanimAd}</option>
                                                ))
                                            }


                                        </select>
                                        <button className="btn btn-danger"
                                                onClick={e => addGainEvent(e)}>Add Gain
                                        </button>

                                        {
                                            selectedActivityPart.gains.map((gain, key) => (
                                                <li
                                                    className="d-flex align-items-center justify-content-between py-2 border-bottom"
                                                    key={key}
                                                >
                                                    <div className="small pe-4">
                                                        {gain.gain}
                                                    </div>
                                                    <div className="form-group">
                                                        <button className="btn btn-danger"
                                                                onClick={e => deleteGainEvent(gain.id)}>Delete
                                                        </button>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                : null
                        }
                    </div>
                    <ActivityPartPreview selectedActivityPart={selectedActivityPart}/>
                </div>
            </div>
            :
            null


    )
}